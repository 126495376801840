import React from "react";
import Typography from "@material-ui/core/Typography";

const ContentUnit = ({ styles }) => {
    return (
        <Typography>
            <ol style={styles.ol}>
                <li style={styles.li}>
                    Jelen szerződés a Tanuló és a Nyelviskola között idegennyelv-oktatási szolgáltatásra irányuló
                    felnőttképzési szerződés alapján (a továbbiakban: <strong>„Tanulói Szerződés”</strong>) a Felek
                    jogait és kötelezettségeit szabályozza. A Nyelviskola a szerződés teljesítése körében az oktatási
                    feladatokat az Up2You Unit Korlátolt Felelősségű Társaság és Up2You Group Korlátolt Felelősségű
                    Társaság, valamint e társaságokkal szerződéses jogviszonyban álló tanárai útján biztosítja. A
                    Tanulói Szerződés alapján a Nyelviskola köteles a Tanuló által választott nyelvből nyelvi képzést
                    biztosítani, a Tanuló ellenértékként pedig meghatározott díjat fizetni. A képzés helyszíne: 1053
                    Budapest, Ferenciek tere 7-8. II. lépcsőház I.4.
                </li>
                <li style={styles.li}>
                    Az oktatási program megvalósítása bérletrendszerben történik. Az érvényes bérlet feljogosítja a
                    Tanulót arra, hogy a Nyelviskola nyitvatartási idejében előzetesen bejelentett időpontban az általa
                    választott – és a Nyelviskola által oktatott – nyelvből nyelvoktatásban részesüljön. A Tanuló által
                    igényelt bérlet érvényességi ideje 30 nap, mely időtartam az első igénybe vett tanórától indul és az
                    onnantól számított 30. napon jár le. A bérlet díját, a bérlet érvényességének kezdetét, a bérlettel
                    igénybe vehető óraszámot, a hátralévő órákat Felek a bérleten tartják nyilván, illetve a Nyelviskola
                    erről külön nyilvántartást is vezet. A bérlet névre szól, nem átruházható. A bérlet a Tanulói
                    Szerződés elválaszthatatlan részét képezi.
                </li>
                <li style={styles.li}>
                    Tanuló a Nyelviskola szolgáltatásainak igénybevételére jogosító bérletért díj fizetésére köteles. A
                    Nyelviskola a bérlet díját (a továbbiakban: <strong>„Díj”</strong>) az ÁSZF 1. számú mellékletét
                    képező szolgáltatás igénybevételére vonatkozó díjtáblázat alapján határozza meg. A Díjat a Tanuló
                    előre esedékesen köteles megfizetni az erről szóló számla ellenében banki átutalás vagy készpénzzel
                    történő fizetés útján. A Díj megfizetését követően a Tanuló legkésőbb az első igénybe vett tanórát
                    megelőzően a bérletet kézhez kapja. Jelen szerződés megszűnik, amennyiben a bérlet érvényességi
                    ideje letelt, és a Tanuló annak meghosszabbítását a bérlet érvényességi idejének lejártát követő 15
                    napon belül nem kezdeményezte.
                </li>
                <li style={styles.li}>
                    Tanuló tudomásul vette, hogy a Tanulói Szerződés részletes feltételeit a Nyelviskola
                    <a href="https://up2you.hu/uploads/docs/up2you-aszf.pdf"> Általános Szerződési Feltételeit</a>{" "}
                    tartalmazó dokumentum (a továbbiakban: <strong>„ÁSZF”</strong>) tartalmazza, amely jelen szerződés
                    elválaszthatatlan részét képezi.
                </li>
                <li style={styles.li}>
                    <strong>
                        A Tanuló jelen szerződés aláírásával kijelenti, hogy a Nyelviskola{" "}
                        <a href="https://up2you.hu/uploads/docs/up2you-aszf.pdf">Általános Szerződési Feltételeit</a>{" "}
                        jelen szerződés megkötését megelőzően elektronikusan vagy nyomtatott formában kézhez kapta és –
                        különös tekintettel annak 5. fejezetére – jelen szerződés megkötését megelőzően elolvasta, az
                        abban foglalt rendelkezéseket megértette és magára nézve kötelezőnek fogadja el.
                    </strong>
                </li>
                <li style={styles.li}>
                    Jelen szerződésben nem szabályozott kérdésekben a polgári törvénykönyvről szóló 2013. évi V. törvény
                    rendelkezései az irányadók.
                </li>
                <li style={styles.li}>
                    Jelen szerződést Felek elolvasás és értelmezés után, mint akaratukkal mindenben egyezőt jóváhagyólag
                    írták alá.
                </li>
            </ol>
        </Typography>
    );
};

export default ContentUnit;

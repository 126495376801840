const texts = [
    {
        hu: "Tanulói szerződés",
        en: "Student contract",
    },
    {
        hu: "amely létrejött egyrészről",
        en: "made by and between",
    },
    {
        hu: "mint a nyelviskola (a továbbiakban: „Nyelviskola”),",
        en: "as language school (hereinafter: “Language School”),",
    },
    {
        hu: "másrészről",
        en: "and",
    },
    {
        hu: "mint tanuló, (a továbbiakban: „Tanuló”),",
        en: "as student (hereinafter: “Student”),",
    },
    {
        hu: "Tanuló és Nyelviskola a továbbiakban együttesen: „Felek” között az alulírott napon és helyen az alábbi feltételekkel:",
        en: "the Student and the Language School hereinafter together as “Parties”, at the below-written date and place, under the following terms and conditions:",
    },
    {
        hu: "Cégnév",
        en: "Company name",
    },
    {
        hu: "Székhely",
        en: "Registered office",
    },
    {
        hu: "Képviselő",
        en: "Represented by",
    },
    {
        hu: "Cégjegyzékszám",
        en: "Company registration number",
    },
    {
        hu: "Adószám",
        en: "Tax identification number",
    },
    {
        hu: "Nyilvántartási szám",
        en: "Registration number",
    },
    {
        hu: "Név",
        en: "Name",
    },
    {
        hu: "Lakóhely",
        en: "Residential address",
    },
    {
        hu: "Anyja neve",
        en: "Mother's name:",
    },
    {
        hu: "Születési hely",
        en: "Place of birth:",
    },
    {
        hu: "Születési idő",
        en: "Date of birth",
    },
    {
        hu: "Adóazonosító jele",
        en: "Tax identification number",
    },
    {
        hu: "Oktatási azonosító szám",
        en: "Education ID number",
    },
    {
        hu: "Email",
        en: "Email",
    },
    {
        hu: "Telefonszám",
        en: "Phone number",
    },
    {
        hu: "Választott nyelv",
        en: "Selected language",
    },
    {
        hu: "Hozzájárulok ahhoz, hogy az oktatás helyszínén és az on-line tanórák ideje alatt – előzetes értesítés mellett – a Nyelviskola rólam fénykép és hangfelvételt, illetve filmfelvételt készítsen, a rólam készített fénykép és hangfelvételt, illetve filmfelvételt további feltétel és korlátozás, illetve külön nyilatkozat nélkül üzleti, promóciós célokra, akár belföldön, akár külföldön ellenérték fizetése nélkül felhasználhatja, nyilvánosan közzéteheti, a felvételt átdolgozhatja, illetve szerzői jog által védett alkotáshoz felhasználhatja, harmadik fél részére szerződés alapján átadhatja, illetve a felvétel felhasználásával kapcsolatban harmadik személy részére felhasználási jogot biztosíthat. A fenti felhasználási jog a jelen nyilatkozattól számított öt évig érvényes. A Nyelviskola részletes adatkezelési szabályzatát az ÁSZF tartalmazza.",
        en: "I consent to the Language School taking photographs, voice recordings and film recordings about me at the place of education, during the lessons, subject to prior notice, furthermore the school may use, publicly publish, rework the photo or recording taken/made about me, may use it for copyrighted works, may transfer it to a third party under a contract or may grant the right of use to a third party in connection with using the recording, for business and promotional purposes, either domestically or abroad, without any separate declaration and without paying any consideration. The above right of use is valid for five years from the date of this declaration. The detailed privacy policy of the Language School is specified in the GTC.",
    },
    {
        hu: "Hozzájárulok ahhoz, hogy a megadott e-mail címre a Nyelviskola e-mailben tájékoztasson a Nyelviskola által nyújtott szolgáltatásokról, promóciókról, kedvezményekről, illetve az ehhez kapcsolódó friss hírekről. A Tanuló az adatainak megadásával felhatalmazza a Nyelviskolát, hogy e hozzájáruló nyilatkozat visszavonásáig ezen adatokat az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. tv., valamint a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. tv.  rendelkezéseinek megfelelően kezelje. A Nyelviskola részletes adatkezelési szabályzatát az ÁSZF tartalmazza.",
        en: "I consent to the Language School informing me via the provided e-mail about the services, promotions and discounts offered by the Language School and about the latest related news. By giving his/her data, the Student authorises the Language School to process such data in conformity with the provisions of Act CXII of 2011 on informational self-determination and on the freedom of information as well as Act XLVIII of 2008 on the basic requirements and certain restrictions of commercial advertising activities until withdrawal of this declaration of consent. The detailed privacy policy of the Language School is specified in the GTC.",
    },
    {
        hu: "Kijelentem, hogy jelen szerződés minden pontját elolvastam, tudomásul veszem és elfogadom.",
        en: "I declare that I have read, acknowledge and accept all parts of this contract.",
    },
    {
        hu: "Aláírom",
        en: "Sign",
    },
    {
        hu: "A szerződés aláírásának időpontja:",
        en: "Date of sign:",
    },
    {
        hu: "Fogadd el a szerződés feltételeket",
        en: "Accept the terms of the contract",
    },
    {
        hu: "Kedves Tanulónk!",
        en: "Dear Student!",
    },
    {
        hu: "Kérjük, olvasd át figyelmesen a Tanulói Szerződést, és töltsd ki személyes adataiddal! Amennyiben hozzájárulsz ahhoz, hogy kép- és hangfelvételt készítsünk Rólad, valamint Hírlevelet küldjünk Neked, jelöld a megfelelő helyen!",
        en: "Please read the Student contract carefully and fill in your personal details! If you consent to us taking a picture and video/audio of you and sending you a Newsletter, mark it in the appropriate place.",
    },
    {
        hu: "Figyelj a pontos kitöltésre, mert később a módosításra már nem lesz lehetőséged!",
        en: "Pay attention to details during filling in, because you will not be able to change these parts later.",
    },
    {
        hu: "Ne felejtsd el aláírásoddal véglegesíteni a szerződés elfogadását! Köszönjük.",
        en: "Don't forget to finalize the acceptance of the contract with your signature! Thank You.",
    },
    {
        hu: "Up2You Nyelviskola",
        en: "Up2You Language School",
    },
];

export default texts;

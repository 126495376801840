import React from "react";
import Typography from "@material-ui/core/Typography";

const ContentUnit = ({ styles }) => {
    return (
        <Typography>
            <ol style={styles.ol}>
                <li style={styles.li}>
                    This contract governs the rights and obligations of the Parties under the adult education contract
                    for foreign language teaching services between the Student and the Language School (hereinafter:
                    “Student Contract”). With a view to contractual fulfilment, the Language School provides the
                    educational tasks through Up2You Unit Korlátolt Felelősségű Társaság and Up2You Group Korlátolt
                    Felelősségű Társaság as well as its teachers contracted with these companies. Pursuant to the
                    Student Contract, the Language School is obliged to provide language training in the language chosen
                    by the Student, for which the Student is required to pay a specified fee. Venue of the training:
                    1053 Budapest, Ferenciek tere 7-8. II. lépcsőház I.4.
                </li>
                <li style={styles.li}>
                    The educational programme is implemented in a season pass system. With the valid pass the Student
                    can receive language training in the language of his/her choice, and taught by the Language School,
                    at the time announced in advance and during the opening hours of the Language School. The validity
                    period of the pass requested by the Student is 30 days, starting from the first lesson taken and
                    ending on the 30th day thereafter. The description of the training, the fee of the pass, the
                    beginning of the validity of the pass, the number of lessons that can be taken with the pass and the
                    remaining lessons are recorded by the Parties on the pass, and the Language School also keeps a
                    separate registration about it. The pass is issued to name, and it is non-transferable. The pass
                    forms an inseparable part of the Student Contract.
                </li>
                <li style={styles.li}>
                    The Student is obliged to pay a fee for the pass entitling him/her to use the services of the
                    Language School. The Language School specifies the fee of the pass (hereinafter: “Fee”) on the basis
                    of the fee table for using the service attached to the GTC as annex no. 1. The Student is obliged to
                    pay the Fee in advance, against the relevant invoice, via banking transfer or by payment in cash.
                    After paying the Fee, the Student receives the pass at the latest prior to the first lesson taken.
                    This contract shall terminate if the validity period of the pass expired and the Student did not
                    request its extension within 15 days after the expiry of the validity period of the pass.
                </li>
                <li style={styles.li}>
                    The Language School informs the Student that previously acquired knowledge may not be taken into
                    account based on the provisions of the training programme. Furthermore, the Student is notified that
                    his/her performance during the training will not be assessed and checked, and no exam is attached to
                    the training. The completion of the training is confirmed by a certificate, and the detailed
                    conditions on issuing the certificate are specified in the GTC.
                </li>
                <li style={styles.li}>
                    The Student acknowledged that the detailed terms and conditions of the Student Contract are laid
                    down in the document specifying the General Terms and Conditions of the Language School
                    (hereinafter: “GTC”), which forms an integral part of this Contract.
                </li>
                <li style={styles.li}>
                    By signing this contract, the Student consents to the Language School forwarding his/her natural
                    personal identification data, electronic mail address and tax identification code to the state
                    administrative body of adult education in the data provision system of adult education, as indicated
                    in the GTC.
                </li>
                <li style={styles.li}>
                    <strong>
                        By signing this contract, the Student declares to have received the{" "}
                        <a href="https://up2you.hu/uploads/docs/up2you-aszf.pdf">
                            General Terms and Conditions of the Language School
                        </a>{" "}
                        electronically or in printed form before concluding this contract and, with special regard to
                        chapter 5 thereof, s/he has read, understood and accepted the provisions of this contract as
                        binding on him/her.
                    </strong>
                </li>
                <li style={styles.li}>
                    Issues not regulated by this contract are governed by the provisions of Act LXXVII of 2013 on adult
                    training, governmental decree 11/2020. (II. 7.) on implementing the Act on adult training as well as
                    Act V of 2013 on the Civil Code.
                </li>
                <li style={styles.li}>
                    After reading, interpreting and having found this contract to be in full conformity with their
                    intentions, the Parties have signed it for approval in three original, Hungarian counterparts.
                </li>
            </ol>
        </Typography>
    );
};

export default ContentUnit;

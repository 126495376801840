import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { history } from "../../routing";

import Theme from "../Theme/Theme";
import Main from "../Main";
import Contract from "../Contract";
import Header from "../Header";

const App = () => {
    return (
        <Theme>
            <Header />
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route path="/szerzodes/:contractId" component={Contract} />

                    <Redirect to="/" />
                </Switch>
            </Router>
        </Theme>
    );
};

export default App;

import React from "react";

import TextField from "@material-ui/core/TextField";
import useText from "../Texts/useText";

const schools = {
    UNIT: {
        name: "Up2You Unit Kft.",
        address: "1053 Budapest, Ferenciek tere 7-8. I. lépcsőház, 1. emelet / 2.",
        representative: "Ballagó Zoltán",
        regNumber: "01-09-308266",
        taxNumber: "26216377-1-42",
    },
    "UNIT-HU": {
        name: "Up2You Unit Kft.",
        address: "1053 Budapest, Ferenciek tere 7-8. I. lépcsőház, 1. emelet / 2.",
        representative: "Ballagó Zoltán",
        regNumber: "01-09-308266",
        taxNumber: "26216377-1-42",
        idNumber: "B/2020/005337",
    },
    "UNIT-EN": {
        name: "Up2You Unit Kft.",
        address: "1053 Budapest, Ferenciek tere 7-8. I. lépcsőház, 1. emelet / 2.",
        representative: "Ballagó Zoltán",
        regNumber: "01-09-308266",
        taxNumber: "26216377-1-42",
        idNumber: "B/2020/005337",
    },
    GROUP: {
        name: "Up2You Group Kft.",
        address: "1053 Budapest, Ferenciek tere 7-8. I. lépcsőház, 1. emelet / 2.",
        representative: "Ballagó Zoltán",
        regNumber: "01-09-308285",
        taxNumber: "26216700-1-42",
    },
};

const SchoolData = ({ contractType }) => {
    const texts = useText();
    return (
        <>
            {contractType in schools && (
                <>
                    <TextField
                        id="standard-basic"
                        label={texts[6]}
                        fullWidth
                        value={schools[contractType].name}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="standard-basic"
                        label={texts[7]}
                        fullWidth
                        value={schools[contractType].address}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="standard-basic"
                        label={texts[8]}
                        fullWidth
                        value={schools[contractType].representative}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="standard-basic"
                        label={texts[9]}
                        fullWidth
                        value={schools[contractType].regNumber}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        id="standard-basic"
                        label={texts[10]}
                        fullWidth
                        value={schools[contractType].taxNumber}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    {schools[contractType].idNumber && (
                        <TextField
                            id="standard-basic"
                            label={texts[11]}
                            fullWidth
                            value={schools[contractType].idNumber}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default SchoolData;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../../images/logo-white.png';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#3B3D44',
  },
  logo: {
    fontSize: '15px',
    textTransform: 'uppercase',
    fontWeight: 900,
    '& > img': {
        height: 60,
        verticalAlign: 'middle',
        display: 'inline-block',
        marginRight: 15
    }
  }
}));

const Header = () => {
  const classes = useStyles();

  return (
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          <Typography variant="h6" className={classes.logo}>
            <img src={logo} alt="Up2You Nyelviskola"/>Up2You Nyelviskola
          </Typography>
        </Toolbar>
      </AppBar>
  );
}

export default Header;

import React from "react";
import useText from "../Texts/useText";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

const Consents = ({ contract, updateField, validation }) => {
    const handleChange = (fieldName, ev) => {
        updateField(fieldName, ev.target.checked);
    };
    const texts = useText();
    return (
        <>
            <FormControl margin="normal" fullWidth>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={contract.consent_recording}
                            onChange={(ev) => handleChange("consent_recording", ev)}
                            name="consentRecording"
                            color="primary"
                            disabled={contract.contract_status === "1"}
                        />
                    }
                    label={texts[22]}
                />
            </FormControl>

            <FormControl margin="normal" fullWidth>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={contract.consent_newsletter}
                            onChange={(ev) => handleChange("consent_newsletter", ev)}
                            name="consentNewsletter"
                            color="primary"
                            disabled={contract.contract_status === "1"}
                        />
                    }
                    label={texts[23]}
                />
            </FormControl>

            <FormControl margin="normal" fullWidth error={validation.hasOwnProperty("declaration")}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={contract.declaration}
                            onChange={(ev) => handleChange("declaration", ev)}
                            name="contractDeclaration"
                            color="primary"
                            disabled={contract.contract_status === "1"}
                        />
                    }
                    label={texts[24]}
                />
                {validation.hasOwnProperty("declaration") && (
                    <FormHelperText>{validation["declaration"]}</FormHelperText>
                )}
            </FormControl>
        </>
    );
};

export default Consents;

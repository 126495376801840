import React from "react";
import useText from "../Texts/useText";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const StudentData = ({ contract, languages, updateField, validation }) => {
    const texts = useText();
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <TextField
                label={texts[12]}
                error={validation.hasOwnProperty("student_name")}
                helperText={validation["student_name"]}
                fullWidth
                value={contract.student_name}
                onChange={(ev) => updateField("student_name", ev.target.value)}
                margin="normal"
                variant={contract.contract_status === "1" ? "outlined" : "filled"}
                InputProps={{
                    readOnly: contract.contract_status === "1",
                }}
            />

            <TextField
                label={texts[13]}
                error={validation.hasOwnProperty("student_address")}
                helperText={validation["student_address"]}
                fullWidth
                value={contract.student_address}
                onChange={(ev) => updateField("student_address", ev.target.value)}
                margin="normal"
                variant={contract.contract_status === "1" ? "outlined" : "filled"}
                InputProps={{
                    readOnly: contract.contract_status === "1",
                }}
            />

            <TextField
                label={texts[14]}
                error={validation.hasOwnProperty("student_mother")}
                helperText={validation["student_mother"]}
                fullWidth
                value={contract.student_mother}
                onChange={(ev) => updateField("student_mother", ev.target.value)}
                margin="normal"
                variant={contract.contract_status === "1" ? "outlined" : "filled"}
                InputProps={{
                    readOnly: contract.contract_status === "1",
                }}
            />

            <TextField
                label={texts[15]}
                error={validation.hasOwnProperty("student_placeofbirth")}
                helperText={validation["student_placeofbirth"]}
                fullWidth
                value={contract.student_placeofbirth}
                onChange={(ev) => updateField("student_placeofbirth", ev.target.value)}
                margin="normal"
                variant={contract.contract_status === "1" ? "outlined" : "filled"}
                InputProps={{
                    readOnly: contract.contract_status === "1",
                }}
            />

            <FormControl margin="normal" fullWidth>
                <KeyboardDatePicker
                    label={texts[16]}
                    error={validation.hasOwnProperty("student_dateofbirth")}
                    helperText={validation["student_dateofbirth"]}
                    fullWidth
                    value={contract.student_dateofbirth}
                    onChange={(date) => updateField("student_dateofbirth", date ? date.format("YYYY.MM.DD") : "")}
                    format="YYYY.MM.DD"
                    inputVariant={contract.contract_status === "1" ? "outlined" : "filled"}
                    readOnly={contract.contract_status === "1"}
                    disableFuture
                />
            </FormControl>

            {["UNIT-HU", "UNIT-EN"].includes(contract.contract_type) && (
                <>
                    <TextField
                        label={texts[17]}
                        error={validation.hasOwnProperty("student_taxid")}
                        helperText={validation["student_taxid"]}
                        fullWidth
                        value={contract.student_taxid}
                        onChange={(ev) => updateField("student_taxid", ev.target.value)}
                        margin="normal"
                        variant={contract.contract_status === "1" ? "outlined" : "filled"}
                        InputProps={{
                            readOnly: contract.contract_status === "1",
                        }}
                    />

                    <TextField
                        label={texts[18]}
                        error={validation.hasOwnProperty("student_eduid")}
                        helperText={validation["student_eduid"]}
                        fullWidth
                        value={contract.student_eduid}
                        onChange={(ev) => updateField("student_eduid", ev.target.value)}
                        margin="normal"
                        variant={contract.contract_status === "1" ? "outlined" : "filled"}
                        InputProps={{
                            readOnly: contract.contract_status === "1",
                        }}
                    />
                </>
            )}

            <TextField
                label={texts[19]}
                error={validation.hasOwnProperty("student_email")}
                helperText={validation["student_email"]}
                fullWidth
                value={contract.student_email}
                onChange={(ev) => updateField("student_email", ev.target.value)}
                margin="normal"
                variant={contract.contract_status === "1" ? "outlined" : "filled"}
                InputProps={{
                    readOnly: contract.contract_status === "1",
                }}
            />

            <TextField
                id="standard-basic"
                error={validation.hasOwnProperty("student_phone")}
                helperText={validation["student_phone"]}
                label={texts[20]}
                fullWidth
                value={contract.student_phone}
                onChange={(ev) => updateField("student_phone", ev.target.value)}
                margin="normal"
                variant={contract.contract_status === "1" ? "outlined" : "filled"}
                InputProps={{
                    readOnly: contract.contract_status === "1",
                }}
            />

            <FormControl variant={contract.contract_status === "1" ? "outlined" : "filled"} margin="normal" fullWidth>
                <InputLabel id="language-label">{texts[21]}</InputLabel>
                <Select
                    labelId="language-label"
                    id="language"
                    value={contract.student_language}
                    onChange={(ev) => updateField("student_language", ev.target.value)}
                    label={texts[21]}
                    variant={contract.contract_status === "1" ? "outlined" : "filled"}
                    inputProps={{
                        readOnly: contract.contract_status === "1",
                    }}
                >
                    <MenuItem value="" disabled>
                        <em>-</em>
                    </MenuItem>
                    {Object.keys(languages).map((key) => (
                        <MenuItem key={key} value={key}>
                            {languages[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </MuiPickersUtilsProvider>
    );
};

export default StudentData;

import React from "react";
import useText from "../Texts/useText";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const ContractInfo = () => {
    const texts = useText();
    console.log(texts);
    return (
        <Card elevation={3} style={{ marginBottom: "16px" }}>
            <CardContent>
                <Typography variant="body1" gutterBottom>
                    {texts[28]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {texts[29]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>{texts[30]}</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {texts[31]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {texts[32]}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ContractInfo;

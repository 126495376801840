import React from "react";
import TextContext from "./TextContext";
import texts from "./texts";

const TextContextProvider = ({ contractType, children }) => {
    const language = contractType.includes("-EN") ? "en" : "hu";
    const textByLanguage = texts.map((text) => text[language]);
    return <TextContext.Provider value={textByLanguage}>{children}</TextContext.Provider>;
};

export default TextContextProvider;

export function urlEncode(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export function apiRoot() {
    const host = window.location.hostname;
    let retValue = "";
    switch (host) {
        case "localhost":
            retValue = "http://up2you.localhost:8888/api";
            break;
        case "tanulo.uat.up2you.hu":
            retValue = "https://uat.up2you.hu/api";
            break;
        case "tanulo.up2you.hu":
            retValue = "https://up2you.hu/api";
            break;
        default:
            console.error("Unknown environment");
    }
    return retValue;
}

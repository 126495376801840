import React from "react";
import useText from "../Texts/useText";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import StudentData from "./StudentData";
import SchoolData from "./SchoolData";

const ContractHead = ({ contract, languages, updateField, validation }) => {
    const texts = useText();
    return (
        <>
            <Typography variant="h3" component="h3" gutterBottom>
                {texts[0]}
            </Typography>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <Typography variant="body1" gutterBottom>
                        {texts[1]}
                    </Typography>
                    <SchoolData contractType={contract.contract_type} />
                    <Typography variant="body1" gutterBottom>
                        {texts[2]}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="body1" gutterBottom>
                        {texts[3]}
                    </Typography>
                    <StudentData
                        contract={contract}
                        languages={languages}
                        updateField={updateField}
                        validation={validation}
                    />
                    <Typography variant="body1" gutterBottom>
                        {texts[4]}
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant="body1" gutterBottom>
                {texts[5]}
            </Typography>
        </>
    );
};

export default ContractHead;

import React, { useEffect, useState } from "react";
import axios from "axios";
import qs from "qs";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import { apiRoot } from "../../utils/ajaxUtils";

import ContractIntro from "./ContractIntro";
import ContractHead from "./ContractHead";
import ContractContent from "./ContractContent";
import ContractFooter from "./ContractFooter";
import Consents from "./Consents";

import TextContextProvider from "../Texts/TextContextProvider";

const Contract = ({ match: { params } }) => {
    const { contractId } = params;
    const [isLoading, setIsLoading] = useState(true);
    const [contract, setContract] = useState(null);
    const [languages, setLanguages] = useState({});
    const [validation, setValidation] = useState({});

    const isEn = contract?.contract_type.includes("-EN");

    useEffect(() => {
        axios({
            url: apiRoot() + "/contract/" + contractId,
            method: "get",
            mode: "no-cors",
            withCredentials: true,
        })
            .then((response) => {
                const contractRes = response.data.contract;
                setContract({
                    ...contractRes,
                    consent_newsletter: contractRes.consent_newsletter === "1",
                    consent_recording: contractRes.consent_recording === "1",
                    declaration: contractRes.declaration === "1",
                    date_of_sign: new Date(Date.now()).toLocaleString(),
                });
                setLanguages(response.data.languages);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [contractId]);

    const updateField = (field, value) => setContract({ ...contract, [field]: value });

    const validEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    useEffect(() => {
        const valid = contract
            ? Object.keys(contract).reduce((acc, field) => {
                  let isFieldInvalid =
                      contract[field] === null || contract[field] === ""
                          ? isEn
                              ? "This field is mandatory"
                              : "Mező megadása kötelező"
                          : false;
                  switch (field) {
                      case "student_email":
                          isFieldInvalid = validEmail(contract[field])
                              ? isFieldInvalid
                              : isEn
                              ? "Not a valid email format"
                              : "Nem megfelelő email formátum";
                          break;
                      case "declaration":
                          isFieldInvalid = contract[field]
                              ? isFieldInvalid
                              : isEn
                              ? "Accept the terms of the contract"
                              : "Fogadd el a szerződés feltételeket";
                          break;
                      case "student_taxid":
                          isFieldInvalid = false;
                          break;
                      case "student_eduid":
                          isFieldInvalid = false;
                          break;
                      default:
                          break;
                  }
                  return isFieldInvalid
                      ? {
                            ...acc,
                            [field]: isFieldInvalid,
                        }
                      : acc;
              }, {})
            : { invalid: true };
        setValidation(valid);
    }, [contract, isEn]);

    const isSubmitDisabled = () => {
        return Object.keys(validation).length > 0;
    };

    const handleSubmit = () => {
        axios
            .post(apiRoot() + "/contract/" + contractId, qs.stringify(contract), {
                mode: "no-cors",
                withCredentials: true,
                headers: { "content-type": "application/x-www-form-urlencoded" },
            })
            .then((response) => {
                const contractRes = response.data;
                setContract({
                    ...contractRes,
                    consent_newsletter: contractRes.consent_newsletter === "1",
                    consent_recording: contractRes.consent_recording === "1",
                    declaration: contractRes.declaration === "1",
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Container>
            {isLoading && <div>Loading...</div>}
            {!isLoading && (
                <TextContextProvider contractType={contract.contract_type}>
                    {contract.contract_status === "0" && <ContractIntro />}

                    <Card elevation={3}>
                        <CardContent>
                            <ContractHead
                                contract={contract}
                                languages={languages}
                                updateField={updateField}
                                validation={validation}
                            />
                            <ContractContent contractType={contract.contract_type} />
                            <Consents contract={contract} updateField={updateField} validation={validation} />
                        </CardContent>
                        <CardActions style={{ justifyContent: "center" }}>
                            <ContractFooter
                                contract={contract}
                                isSubmitDisabled={isSubmitDisabled}
                                handleSubmit={handleSubmit}
                            />
                        </CardActions>
                    </Card>
                    <Typography variant="body1" gutterBottom style={{ textAlign: "center", margin: "16px 0" }}>
                        Up2You Nyelviskola
                    </Typography>
                </TextContextProvider>
            )}
        </Container>
    );
};

export default Contract;

import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#f2a832",
            main: "#f39c12",
            dark: "#e29212",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Lato-Up2You, sans-serif"
    }
});

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;

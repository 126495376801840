import React from "react";
import ContentUnit from "./Contents/ContentUnit";
import ContentGroup from "./Contents/ContentGroup";
import ContentUnitHu from "./Contents/ContentUnitHu";
import ContentUnitEn from "./Contents/ContentUnitEn";

const styles = {
    ol: {
        margin: "0",
        padding: "0 0 0 1em",
    },
    li: {
        marginBottom: "16px",
    },
};

const ContractContent = ({ contractType }) => {
    return (
        <>
            {contractType === "UNIT" && <ContentUnit styles={styles} />}
            {contractType === "GROUP" && <ContentGroup styles={styles} />}
            {contractType === "UNIT-HU" && <ContentUnitHu styles={styles} />}
            {contractType === "UNIT-EN" && <ContentUnitEn styles={styles} />}
        </>
    );
};

export default ContractContent;

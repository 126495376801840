import React from "react";
import useText from "../Texts/useText";

import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import Typography from "@material-ui/core/Typography";

const ContractFooter = ({ contract, isSubmitDisabled, handleSubmit }) => {
    const texts = useText();
    return (
        <>
            {contract.contract_status === "0" && (
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<CreateIcon />}
                    disabled={isSubmitDisabled()}
                    onClick={handleSubmit}
                >
                    {texts[25]}
                </Button>
            )}
            {contract.contract_status === "1" && (
                <Typography variant="body1" gutterBottom>
                    {texts[26]} {contract.date_of_sign}
                </Typography>
            )}
        </>
    );
};

export default ContractFooter;
